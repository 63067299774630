
<template>
  <div>
    <SimpleHeader />
    <div class="assets">
      <div class="assets-body ml">
        <div v-loading="loading" class="content-nobg">
          <div class="sale flex">
            <div class="left">
              <div class="assets-title w100 flex align-center">
                <span class="flex1">{{ $t(1041) }}</span>
                <el-button class="fund" @click="showC2cBuy = true">{{
                  $t(1040)
                }}</el-button>
              </div>
              <div class="btns">
                <el-button
                  v-for="btn in c2cRatesData"
                  :key="btn.coin"
                  @click="handleSelectCoin(btn.coin)"
                  :class="['btnx', { selectCoin: selectCoin === btn.coin }]"
                  >{{ btn.coin }}</el-button
                >
              </div>

              <div class="sale-form">
                <el-form
                  :model="drawData"
                  :rules="rules"
                  ref="form"
                >
                  <el-form-item :label="`${$t(1044)}`">
                    <el-select v-model="drawData.coin">
                      <el-option
                        v-for="(item, i) in bankCurrencies"
                        :key="i"
                        :label="item.currency"
                        :value="item.currency"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item prop="amount">
                    <span class="flex">
                      <span class="flex1" style="color: #606266;">{{ $t(1045) }}</span>
                      <!-- <span class="el-icon-sort primary cursor" @click="$router.push({ name: 'Transfer', query:{to:'C2C', from:'SPOT'} })">{{$t(188)}}</span> -->
                      <el-button
                        type="text"
                        class="el-icon-sort"
                        @click="$router.push({ name: 'Transfer', query:{to:'C2C', from:'SPOT'} })"
                        >{{ $t(188) }}
                        </el-button
                      >
                    </span>
                    <el-input
                      type="Number"
                      v-model="drawData.amount"
                      :placeholder="saleAmount"
                    >
                      <template slot="append">
                        <div class="enterNum-append">
                          <span>{{ selectCoin }}</span>
                          <el-button
                            type="text"
                            @click="drawData.amount = maxAmount"
                            >{{ $t(1047) }}</el-button
                          >
                        </div>
                      </template>
                    </el-input>
                  </el-form-item>
                  <div class="flex color666 fs12" style="margin-top: -10px;">
                      <div class="flex1" style="line-height: 36px;">
                        <span>{{ $t(1048) }} ≈ {{ curReference }} {{drawData.coin}}</span>
                        <span class="ml10">{{ $t(215) }}：{{ maxAmount }} USDT</span>
                      </div>
                      <div class="recive" style="margin-top: -10px;">
                        <span v-if="!isBank">
                        {{ $t(1053) }}
                          <el-button
                            class="btnx mt10 el-icon-right"
                            type="text"
                            @click="$router.push({ name: 'BankSetting' })"
                            >{{ $t(1083) }}</el-button>
                        </span>
                      </div>
                  </div>
                  <div v-if="isBank" class="bank">
                    <p class="fwb mb10">{{ $t(1049) }}</p>
                    <p class="flex">
                      <span>{{ $t(1050) }}：</span>
                      <span>{{ isBank.bankBranch }}</span>
                    </p>
                    <p class="flex">
                      <span>{{ $t(1051) }}：</span>
                      <span>{{ isBank.cardNo? isBank.cardNo.replace(/.*(.{3})/, '****$1') : '' }}</span>
                    </p>
                    <p class="flex">
                      <span>{{ $t(1052) }}：</span>
                      <span>{{ isBank.realname }}</span>
                    </p>
                  </div>
                </el-form>

              </div>
              <div class="flex mb10">
                <el-button
                  type="danger"
                  :disabled="!isBank"
                  :loading="loading"
                  @click="handleSentDraw"
                  >{{ $t(1041) }}
                </el-button>
              </div>
            </div>
            <div class="right">
              <div class="filter-btns">
                <p>{{ $t(1056) }}</p>
                <div class="flex mb20">
                  <el-button
                    class="btnx"
                    :class="{ active: history === 'deposit' }"
                    @click="handleFilter('deposit')"
                    >{{ $t(1040) }}</el-button
                  >
                  <el-button
                    class="btnx"
                    :class="{ active: history === 'withdraw' }"
                    @click="handleFilter('withdraw')"
                    >{{ $t(1041) }}</el-button
                  >
                </div>
                <p>{{ $t(1057) }}</p>
                <div class="flex mb10">
                  <el-button
                    class="btnx"
                    :class="{ active: queryData.status === 0 }"
                    @click="handleFilter('', 0)"
                    >{{ $t(1047) }}</el-button
                  >
                  <el-button
                    class="btnx"
                    :class="{ active: queryData.status === 1 }"
                    @click="handleFilter('', 1)"
                    >{{ $t(1058) }}</el-button
                  >
                  <el-button
                    class="btnx"
                    :class="{ active: queryData.status === 2 }"
                    @click="handleFilter('', 2)"
                    >{{ $t(1059) }}</el-button
                  >
                  <el-button
                    class="btnx"
                    :class="{ active: queryData.status === 3 }"
                    @click="handleFilter('', 3)"
                    >{{ $t(1060) }}</el-button
                  >
                </div>
              </div>
              <div v-loading="historyLoading" class="content-nobg">
                <el-table 
                :data="c2cHistoryData" 
                height="500px"
                v-el-table-infinite-scroll="load"
                class="c2c-table">
                  <el-table-column type="index">
                  </el-table-column>                  
                  <el-table-column prop="status" :label="$t(1084)">
                    <template slot-scope="scope">
                      <span v-if="scope.row.status == 1">{{ $t(1058) }}</span>
                      <span v-if="scope.row.status == 2">{{ $t(1059) }}</span>
                      <span class="mr10" v-if="scope.row.status == 3">{{ $t(1060) }}</span><span class="fs12 color666" v-if="scope.row.auditComment">({{ scope.row.auditComment }})</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="coin" :label="$t(1085)">
                  </el-table-column>
                  <el-table-column prop="exchangeRate" :label="$t(74)">
                    <template slot-scope="scope">
                      <span
                        >{{ getExchangeRate(scope.row)
                        }}{{ scope.row.currency }}</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="createTime" :label="$t(1082)">
                    <template slot-scope="scope">
                      <span>{{ formatDateTimefun(scope.row.createTime) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="soldAmount" :label="$t(1061)">
                    <template slot-scope="scope">
                      <span
                        >{{
                          scope.row.soldAmount
                            ? scope.row.soldAmount
                            : scope.row.buyAmount
                        }}
                        {{ scope.row.coin }}</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="exchangeAmount"
                    :label="$t(1086)"
                    width="200"
                  >
                    <template slot-scope="scope">
                      <span
                        >{{ scope.row.exchangeAmount }}
                        {{ scope.row.currency }}</span
                      >
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="bankCode" :label="$t(1050)">
                  </el-table-column>
                  <el-table-column
                    prop="cardNo"
                    :label="$t(1087)"
                    :width="'200px'"
                  >
                  </el-table-column> -->
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="assets-foot mt10">
        <div class="text-center">
          <el-button type="danger" :loading="loading" @click="handleSentDraw">{{
            $t(1041)
          }}</el-button>
        </div>
      </div> -->
    </div>
    <C2cBuy v-model="showC2cBuy" v-if="showC2cBuy" />
  </div>
</template>
<script>
import {
  getC2cRates,
  getC2cDepositHistory,
  getC2cWithdrawHistory,
  editUserInfo,
  getBankCards,
  sentC2cDraw,
} from "@/api";
import elTableInfiniteScroll from "el-table-infinite-scroll";
import { formatDateTime } from "@/utils";
import C2cBuy from "./components/C2cBuy";
import SimpleHeader from "@/components/SimpleHeader";
// import utils from "@/utils/misc";
export default {
  name: "SettingNickname",
  components: { SimpleHeader, C2cBuy },
  props: {
    value: Boolean,
    // curCoinList: Array
  },
  directives: {
    "el-table-infinite-scroll": elTableInfiniteScroll,
  },
  data() {
    return {
      loading: false,
      historyLoading: false,
      showBtns: false,
      history: "withdraw",
      showC2cBuy: false,
      queryData: {
        status: 0,
        lastId: 0,
        pageSize: 10,
      },
      drawData: {
        amount: '',
        cardId: 0,
        coin: "USD",
        currency: "",
      },
      activeName: "first",
      selectCoin: "ETH",
      c2cRatesData: [],
      c2cHistoryData: [],
      cardsData: [],
      selectBank: "USD",
    };
  },
  mounted() {
    this.fetchC2cRates();
  },
  computed: {
    saleAmount(){
       const max = +(window.configObj.withdrawCashUsdtMaxAmount) || 0
       const min = +(window.configObj.withdrawCashUsdtMinAmount) || 0
       let text
       if ( !max && !min ) text = this.$t(1100)
       if ( min && !max ) text = `${this.$t(1046)}: ${min} ~ ${this.$t(1100)}`
       if ( !min && max ) text = `${this.$t(1046)} ${max}`
       return text
    },  
    bankCurrencies() {
      return this.$store.state.currencies;
    },
    //法币兑换usdt的汇率 this.$store.state.currencies
    coinToCoin() {
      return this.$store.state.spotList[this.selectCoin + "USDT"]
        ? this.$store.state.spotList[this.selectCoin + "USDT"].price
        : 1;
    },
    curReference() {
      return (
        (this.$store.state.currencies[this.drawData.coin]
          ? this.$store.state.currencies[this.drawData.coin].rate
          : 1) * this.coinToCoin
      ).toFixed(2);
    },
    curAssets() {
      return this.$store.getters.assetsMap.assets.C2C || {};
    },
    curCoinList() {
      const arr = this.curAssets.coins ? [...this.curAssets.coins] : [];
      //   if (this.hideSmall) {
      //     return arr.filter((item) => item.totalAmount > 0);
      //   }
      //   if (this.keywords !== "") {
      //     return arr.filter(
      //       (item) => item.coin.indexOf(this.keywords.toUpperCase()) > -1
      //     );
      //   }
      return arr;
    },
    maxAmount() {
      if (this.curCoinList.length > 0) {
        const obj = this.curCoinList.find((e) => e.coin === this.selectCoin)
        return obj ? obj.availableAmount : ''
      }
      return ''
    },
    isBank() {
      return this.cardsData.find(
        (item) => item.currency === this.drawData.coin
      );
    },
    rules() {
      return {
        amount: [
          {
            validator: (rule, value, callback) => {
              if (value === "" || +value <= 0) {
                return callback(new Error(this.$t(1043)));
              }
              callback();
            },
          },
        ],
      };
    },
  },
  methods: {
    load() {
      if (this.c2cHistoryData && this.c2cHistoryData.length < 1) return; // first fetch
      if (this.c2cHistoryData && this.c2cHistoryData.length < 10) return; // no more
      this.queryData.lastId = this.c2cHistoryData[this.c2cHistoryData.length - 1].id,
      this.handleC2cHistory(this.queryData, true);
    },  
    getExchangeRate(item) {
      if (item.exchangeRate) return item.exchangeRate;
      let amount =
        this.history === "deposit" ? item.buyAmount : item.soldAmount;
      return (item.exchangeAmount / amount).toFixed(4).toString();
    },
    formatDateTimefun(val) {
      return formatDateTime(val);
    },
    doClose() {
      this.$emit("input", false);
    },
    handleSelectCoin(type) {
      this.selectCoin = type;
    },
    fetchC2cRates() {
      this.loading = true;
      Promise.all([
        getC2cRates(),
        this.handleC2cHistory(this.queryData),
        getBankCards(),
      ])
        .then((res) => {
          // this.c2cRatesData = res[0].coins.length > 0 ? res[0].coins : [];
          this.c2cRatesData = [
            {
              coin: "USDT",
            },
          ];
          this.cardsData = res[2].cards ? res[2].cards : [];
          this.selectCoin = "USDT";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleC2cHistory(data, loadFetch) {
      this.historyLoading = true;
      if (this.history === "withdraw") {
        return new Promise((resolve, reject) => {
          getC2cWithdrawHistory(data)
            .then((res) => {
               this.c2cHistoryData = (loadFetch && res.orders) ? 
               this.c2cHistoryData.concat(res.orders) : 
               (loadFetch && !res.orders) ? this.c2cHistoryData : res.orders ;
              resolve();
            })
            .finally(() => {
              this.historyLoading = false;
              if (loadFetch) this.queryData.lastId = 0  
            });
        });
      } else {
        return new Promise((resolve, reject) => {
          getC2cDepositHistory(data)
            .then((res) => {
              this.c2cHistoryData = (loadFetch && res.orders) ? 
              this.c2cHistoryData.concat(res.orders) : 
              (loadFetch && !res.orders) ? this.c2cHistoryData : res.orders ;
              resolve();
            })
            .finally(() => {
              this.historyLoading = false;
              if (loadFetch) this.queryData.lastId = 0
            });
        });
      }
    },
    handleFilter(type, status) {
      if (type) {
        this.history = type;
        this.handleC2cHistory(this.queryData);
      } else {
        this.queryData.status = status;
        this.handleC2cHistory(this.queryData);
      }
    },
    handleSentDraw() {
        this.loading = true;
      if (!this.$store.state.userInfo.verifyStatus){
          this.confirmWarning({
            customClass: "financial-confirmWarning-2",
            title: this.$t(1101),
            content: this.$t(130),
        })
        .then(() => {
           this.$router.push({name: 'AuthVerify'})
        })
        .catch(() => {
           return
        })
        this.loading = false;
        return
      }  
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = {
            amount: +this.drawData.amount,
            cardId: +this.isBank.id,
            coin: this.selectCoin,
            currency: this.drawData.coin,
          };
          sentC2cDraw(data)
            .then((res) => {
              if (res.code === 10030009) {
                if (this.$store.state.userInfo.drawRemark) {
                  this.showErrorMsg(this.$store.state.userInfo.drawRemark)
                } else {
                  this.$store.dispatch('getUserInfo').then(() => {
                    this.showErrorMsg(this.$store.state.userInfo.drawRemark)
                  })
                }
              } else {
                this.drawData.amount = ""  
                this.doClose();
                this.showSuccessMsg(this.$t(1062));
                this.handleC2cHistory(this.queryData)
                this.$store.dispatch('getUserAssets')
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
            this.loading = false;
        }  
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.assets {
  padding: 20px 100px 40px 100px;
  background-color: #eef1f5;
  .assets-title {
    display: inline-flex;
    font-size: 28px;
  }
  .sale {
    padding: 25px;
    min-height: 880px;
    border-radius: 15px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    .btnx {
      height: 36px;
      padding: 5px 10px;
    }
    .left {
      width: 100%;
      padding: 0 15px;
      min-height: 570px;
      //   border-right: 1px solid $borderColor;
      .btns {
        padding: 15px 0;
        border-bottom: 1px solid $borderColor;
      }
      .sale-form {
        padding: 15px 0;
      }
    }
    .right {
      position: relative;
      width: 100%;
      height: fit-content;
      overflow-y: auto;
      padding: 0 15px;
      .sale-record {
        padding: 10px 0;
        .sale-record-item {
          border-bottom: 1px solid $borderColor;
        }
      }
      .filter-btns {
        padding: 15px 25px;
        margin-bottom: 20px;
        background: #eee;
        border-radius: 6px;
        p {
          margin: 5px 0;
        }
        .active {
          background: $primaryColor;
          color: #fff;
        }
      }
      .c2c-table {
        min-height: 500px;
      }
      .no-data {
        margin-top: 50px;
        img {
          max-width: 100px;
        }
      }
    }
  }
}
.selectCoin {
  color: #fff !important;
  background: $primaryColor;
}
/deep/.el-input-group__append {
  color: #999;
  width: 95px;
  padding: 0 20px 0 0;
  .enterNum-append {
    display: inline-flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
}
</style>